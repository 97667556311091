<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                @keyup.enter.native="onQuery"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="warehouseName"
                    label="仓库名称"
                >
                    <el-input
                        v-model="queryFormModel.warehouseName"
                        placeholder="请输入仓库名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="supplierName"
                    label="供应商名称"
                >
                    <el-input
                        v-model="queryFormModel.supplierName"
                        placeholder="请输入供应商名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否有效"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="true"
                        />
                        <el-option
                            label="否"
                            value="false"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/gd/warehouse/warehouse/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
                <el-button-group>
                    <el-button
                        type="danger"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="39"
                />
                <el-table-column
                    prop="warehouseSn"
                    label="仓库编号"
                    min-width="100"
                />
                <el-table-column
                    prop="warehouseName"
                    label="仓库名称"
                    min-width="100"
                />
                <el-table-column
                    prop="supplierName"
                    label="供应商名称"
                    min-width="100"
                />
                <el-table-column
                    prop="warehouseShipMethodName"
                    label="发货方式"
                    min-width="100"
                />
                <el-table-column
                    prop="isAvailable"
                    label="是否有效"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isAvailable ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/gd/warehouse/warehouse/edit/' + scope.row.id)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Warehouse',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                warehouseName: '', // 仓库名称
                supplierName: '',
                isAvailable: '', // 是否有效
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.Warehouse.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Gd.Warehouse.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
    },
};
</script>

<style lang="scss">
</style>
