<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-col :span="12">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="supplierId"
                        label="供应商"
                        label-width="13em"
                    >
                        <RemoteSelect
                            filterable
                            remote="/gd/supplier/data"
                            placeholder="请输入"
                            :props="{id:'id',name:'supplierName'}"
                            v-model="formModel.supplierId"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="warehouseName"
                        label="仓库名称"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.warehouseName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="warehouseSn"
                        label="仓库编号"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.warehouseSn"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isSplitOrderGoods"
                        label="是否按照商品拆单"
                        label-width="13em"
                    >
                        <el-switch
                            :active-value="true"
                            :inactive-value="false"
                            v-model="formModel.isSplitOrderGoods"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isGetOrderPushtowarehouseInfo"
                        label="是否获取订单推送仓库反馈"
                        label-width="13em"
                    >
                        <el-switch
                            :active-value="true"
                            :inactive-value="false"
                            v-model="formModel.isGetOrderPushtowarehouseInfo"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="warehouseShipMethod"
                        label="发货方式"
                        label-width="13em"
                    >
                        <dictionaries-picker
                            type="warehouseShipMethod"
                            v-model="formModel.warehouseShipMethod"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayAppKey"
                        label="网关AppKey"
                        label-width="13em"
                        v-if="formModel.warehouseShipMethod == 20"
                    >
                        <el-input
                            v-model="formModel.gatewayAppKey"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayAppSecret"
                        label="网关AppSecret"
                        label-width="13em"
                        v-if="formModel.warehouseShipMethod == 20"
                    >
                        <el-input
                            v-model="formModel.gatewayAppSecret"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayPlatformName"
                        label="gatewayPlatformName"
                        label-width="13em"
                        v-if="formModel.warehouseShipMethod == 20"
                    >
                        <el-input
                            v-model="formModel.gatewayPlatformName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayPlatformCode"
                        label="gatewayPlatformCode"
                        label-width="13em"
                        v-if="formModel.warehouseShipMethod == 20"
                    >
                        <el-input
                            v-model="formModel.gatewayPlatformCode"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayDistributorName"
                        label="gatewayDistributorName"
                        label-width="13em"
                        v-if="formModel.warehouseShipMethod == 20"
                    >
                        <el-input
                            v-model="formModel.gatewayDistributorName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayEbpCode"
                        label="gatewayEbpCode"
                        label-width="13em"
                        v-if="formModel.warehouseShipMethod == 20"
                    >
                        <el-input
                            v-model="formModel.gatewayEbpCode"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayRecpAccount"
                        label="gatewayRecpAccount"
                        label-width="13em"
                        v-if="formModel.warehouseShipMethod == 20"
                    >
                        <el-input
                            v-model="formModel.gatewayRecpAccount"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayRecpCode"
                        label="gatewayRecpCode"
                        label-width="13em"
                        v-if="formModel.warehouseShipMethod == 20"
                    >
                        <el-input
                            v-model="formModel.gatewayRecpCode"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayRecpName"
                        label="gatewayRecpName"
                        label-width="13em"
                        v-if="formModel.warehouseShipMethod == 20"
                    >
                        <el-input
                            v-model="formModel.gatewayRecpName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="customsPlace"
                        label="申报海关代码"
                        label-width="13em"
                    >
                        <!--                        <el-input
v-model="formModel.customsPlace"
auto-complete="off"
/>-->
                        <el-select
                            v-model="formModel.customsPlace"
                            placeholder="请选择"
                        >
                            <el-option
                                key="GUANGZHOU_ZS"
                                label="GUANGZHOU_ZS 广州（总署版）"
                                value="GUANGZHOU_ZS"
                            />
                            <el-option
                                key="HANGZHOU_ZS"
                                label="HANGZHOU_ZS 杭州（总署版）"
                                value="HANGZHOU_ZS"
                            />
                            <el-option
                                key="NINGBO"
                                label="NINGBO 宁波"
                                value="NINGBO"
                            />
                            <el-option
                                key="ZHENGZHOU_BS"
                                label="ZHENGZHOU_BS 郑州（保税物流中心）"
                                value="ZHENGZHOU_BS"
                            />
                            <el-option
                                key="CHONGQING"
                                label="CHONGQING 重庆"
                                value="CHONGQING"
                            />
                            <el-option
                                key="SHANGHAI_ZS"
                                label="上海（总署版）"
                                value="SHANGHAI_ZS"
                            />
                            <el-option
                                key="SHENZHEN"
                                label="SHENZHEN 深圳"
                                value="SHENZHEN"
                            />
                            <el-option
                                key="ZHENGZHOU_ZH_ZS"
                                label="ZHENGZHOU_ZH_ZS 郑州综保（总署版）"
                                value="ZHENGZHOU_ZH_ZS"
                            />
                            <el-option
                                key="TIANJIN"
                                label="TIANJIN 天津"
                                value="TIANJIN"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        prop="warehouseDesc"
                        label="仓库简介"
                        label-width="13em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.warehouseDesc"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isAvailable"
                        label="是否可用"
                        label-width="13em"
                    >
                        <el-switch
                            :active-value="true"
                            :inactive-value="false"
                            v-model="formModel.isAvailable"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="13em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'WarehouseEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                warehouseName: '', // 仓库名称
                warehouseSn: '', // 仓库编号
                warehouseShipMethod: '',
                gatewayAppKey: '',
                gatewayAppSecret: '',
                gatewayPlatformName: '',
                gatewayPlatformCode: '',
                gatewayDistributorName: '',
                gatewayEbpCode: '',
                gatewayRecpAccount: '',
                gatewayRecpCode: '',
                gatewayRecpName: '',
                customsPlace: '',
                warehouseDesc: '',
                isAvailable: 1, // 是否有效
                supplierId: '',
                isSplitOrderGoods: false,
                isGetOrderPushtowarehouseInfo: false,
            },
            // 表单校验规则
            formRules: {
                warehouseName: [
                    {
                        required: true,
                        message: '请输入仓库名称',
                        trigger: 'blur',
                    },
                ],
                warehouseShipMethod: [
                    {
                        required: true,
                        message: '请选择发货方式',
                    },
                ],
                supplierId: [
                    {
                        required: true,
                        message: '请选择供应商',
                    },
                ],
            },
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Gd.Warehouse.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initWarehouse() {
            this.$api.Gd.Warehouse.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initWarehouse();
        }
    },
};
</script>

<style lang="scss">
</style>
